<template>
    <a class="icon-button is-file" :href="linkPdf" target="_blank">
        <b-icon icon="file-pdf"></b-icon>
    </a>
</template>

<script>
    export default {
        name: "OrderFileDropDown",
        props: {
            data: Object
        },
        computed: {
            linkPdf() {
                let url = this.$http.defaults.baseURL;
                return `${url}/order-pdf/${this.data.order_new}`
            }
        }
    }
</script>

<style scoped>

</style>