<template>
  <div>
    <div class="section-title">
      <h3>{{ $t('order.title')  }}</h3>
      <router-link class="button is-success is-rounded ml-auto"
                   :to="{name:'order-pos'}">
        {{ $t('order.create.action')  }}
      </router-link>
    </div>
    <div class="activity-items">
      <div v-if="!patient.id">
        <select-patient
            @select="prepareComponent"
            :patient.sync="patient"
            class="columns"></select-patient>
      </div>
      <div v-if="patient.id" >
        <p>
          <strong>Paciente:</strong> {{patient.code}}<br>
          <strong>Nombre:</strong> {{patient.full_name}}<br>
        </p>
        <b-button @click="patient={}; params.patient = null; prepareComponent()">Elegir Otro Paciente</b-button>
      </div>
      <form method="get" @submit.prevent="prepareComponent" class="block">
        <br>
        <b-field grouped group-multiline>
          <b-field label="Año"
                   label-position="on-border">
            <b-select v-model="params.year">
              <option
                  v-for="item in yearsRange"
                  :value="item.value"
                  :key="item.value">
                {{ item.text }}
              </option>
            </b-select>
          </b-field>
          <b-field label="Orden"
                   label-position="on-border">
            <b-input v-model="order"
                     @keypress.enter.native="prepareComponent">
            </b-input>
          </b-field>
          <select-company label="Empresa"
                          v-if="params.type === 'only_company'"
                          :saved="{}"
                          @select="onSelectCompany"/>
          <b-field label="Fecha"
                   v-if="!showDateRange"
                   label-position="on-border">
            <b-datepicker v-model="params.date"
                          :max-date="new Date()"
                          @keypress.enter.native="prepareComponent">
            </b-datepicker>
          </b-field>

          <b-field label="Fecha Inicial"
                   v-if="showDateRange"
                   label-position="on-border">
            <b-datepicker v-model="params.start_date"
                          :max-date="new Date()"
                          @keypress.enter.native="prepareComponent">
            </b-datepicker>
          </b-field>


          <b-field label="Fecha Final"
                   v-if="showDateRange"
                   label-position="on-border">
            <b-datepicker v-model="params.end_date"
                          :max-date="new Date()"
                          @keypress.enter.native="prepareComponent">
            </b-datepicker>
          </b-field>
          <b-field>
           <b-field>
             <b-radio v-model="params.type"
                      name="type"
                      native-value="all">
               Todas
             </b-radio>
             <b-radio v-model="params.type"
                      name="type"
                      native-value="only_company">
               Empresas
             </b-radio>
             <b-radio v-model="params.type"
                      name="type"
                      native-value="only_patient">
               Particular
             </b-radio>
           </b-field>
          </b-field>
          <b-field>
            <p class="control">
              <button class="button is-primary" type="submit">
                <b-icon icon="search"></b-icon>
              </button>
            </p>
          </b-field>
        </b-field>
      </form>
      <order-loading
          v-if="loading"
          v-for="item in 5"
          :key="`order-holder-${item}`"

      ></order-loading>
      <order-list-item
		  v-if="!loading"
          v-for="item of data"
          :data="item"
          :key="item.id"
      ></order-list-item>

      <b-pagination
          :total="total"
          rounded
          :per-page="params.perPage"
          :current.sync="params.page"
          @change="prepareComponent"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">
      </b-pagination>
    </div>
  </div>
</template>

<script>
import OrderLoading from "../../components/order/OrderLoading";
import OrderListItem from "../../components/order/OrderListItem";
import SelectCompany from "@/components/company/SelectCompany";
import SelectPatient from "@/components/patient/SelectPatient";

export default {
  name: "IndexOrder",
  components: {SelectCompany, OrderListItem, OrderLoading, SelectPatient},
  props: {
    showDateRange: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: [],
      apiUrl: 'orders',
      loading: true,
      total: 0,
      order: '',
      params: {
        perPage: 10,
        page: 1,
        year: this.$store.state.main.year,
        branch_office: this.$auth.user().active_branch_office,
        type: 'all'
      },
      patient: {}
    }
  },
  computed: {
    yearsRange() {
      const current = new Date().getFullYear();
      const years = [];
      for(let i = 0;i < 3;i++){
        years.push({value: ((current-i) % 10) , text: (current-i) });
      }
      return years;
    }
  },
  mounted() {
    this.prepareComponent();
  },
  methods: {
    prepareComponent() {
      if (this.order !== '') {
        this.params.order = String(this.params.year) + this.order.padStart(7);
      }
      if(this.patient.code){
        this.params.patient = this.patient.code;
      }
      this.getAll(this.params)
          .then(({data, meta = {}}) => {
            this.data = data || [];
            this.total = meta.total;
          })
    },
    onChangePagination() {

    },
    onSelectCompany(company) {
      this.params.company = company.company;
    }
  }
}
</script>

<style scoped>

</style>
