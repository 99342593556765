<template>
  <div class="activity-item">
    <div class="users">
      <div class="avatars">
        <img class="participant" :src="data.elaborate | uiAvatar" alt="">
      </div>
      <div class="meta">
        <span>{{ data.elaborate }}</span>
        <span>Orden {{ data.order_new }}</span>
        <span>Fecha: {{ data.date | date('LL') }} {{ data.time | date('hh:mm A') }}</span>
        <span v-if="data.patient">
          <router-link :to="{name:'patient-show', params: { id: data.patient.code }}">
            Paciente {{ data.patient.full_name }} ({{ data.patient.code }})
          </router-link>
        </span>
      </div>
    </div>
    <div class="details">
      <div class="mr-6">
        <span class="tag is-danger is-light" v-if="data.status === 3">
          Cancelada
        </span>
      </div>
      <div class="amount">
        <span>Importe</span>
        <span>{{ data.total | currency('') }}</span>
        <span v-if="data.note">Nota: {{ data.note }}</span>
        <span v-if="data.has_company">Empresa: {{ data.company_key }}</span>
      </div>
      <order-file-drop-down v-if="data.pdf"
                            :data="data"></order-file-drop-down>

      <ellipsis-drop-down title="Acciones">
        <ellipsis-drop-down-item title="Ver detalles"
                                 body="Mostrar el registro"
                                 :to="{name:'order-show', params: {id: data.order_new}}"
                                 icon="fa fa-eye">
        </ellipsis-drop-down-item>
        <ellipsis-drop-down-item title="Facturar"
                                 v-if="data.can_be_billed_particular && this.$auth.user().data_branch_office.group === 'LAPI' && ($alfalab.enableByKey($auth.user(), {enableKey: 'cash_close_service_enable'}))"
                                 body="Generar la factura para la orden correspondiente"
                                 :to="{name:'order-billed', params: {id: data.order_new}}"
                                 icon="fa fa-file-pdf">
        </ellipsis-drop-down-item>
        <ellipsis-drop-down-item title="Cancelar"
                                 body="Cancelar el elemento actual"
                                 v-if="data.can_cancel && false"
                                 icon="fa fa-trash">
        </ellipsis-drop-down-item>
      </ellipsis-drop-down>
    </div>
  </div>
</template>

<script>
import OrderFileDropDown from "./OrderFileDropDown";
import EllipsisDropDown from "../utils/EllipsisDropDown";
import EllipsisDropDownItem from "../utils/EllipsisDropDownItem";
export default {
  name: "OrderListItem",
  components: {EllipsisDropDownItem, EllipsisDropDown, OrderFileDropDown},
  data(){
    return{
      toggle:false
    }
  },
  props: {
    data: Object
  },
  methods:{
    onClickOutside() {
      this.toggle = false;
    }
  }
}
</script>

<style scoped>

</style>
